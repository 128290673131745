<template>
	<v-card
		elevation="0"
		class="ma-0 pa-0"
	>
		<v-card-text class="pl-0 py-4">
			<v-row justify="space-between">
				<v-col cols="auto" class="py-0">
					<v-alert
						dense
						outlined
						border="left"
						:value="errorOn && !dialog"
						:type="errorType"
						class="ma-0 py-1"
					>
						{{ errorMessage }}
						<font v-if="!isLoaded" @click="getFamily" style="cursor:pointer;"><strong>, click here</strong> to reload</font>
					</v-alert>
				</v-col>
				
				<v-col cols="auto" class="text-right py-0 mb-1">
				
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverNew? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverNew = !hoverNew"
									@mouseleave="hoverNew = !hoverNew"
									@click="newItem"
								>mdi-plus-circle</v-icon>
							</template>
							<small>New</small>
						</v-tooltip>
					</template>
					
					<template>
						<v-tooltip bottom nudge-bottom="-15">
							<template v-slot:activator="{ on: onToolTip }">
								<v-icon
									size="30"
									:color="hoverRefresh? 'blue darken-1' : 'blue darken-2'"
									v-on="onToolTip"
									@mouseover="hoverRefresh = !hoverRefresh"
									@mouseleave="hoverRefresh = !hoverRefresh"
									@click="getFamily"
								>mdi-refresh-circle</v-icon>
							</template>
							<small>Refresh</small>
						</v-tooltip>
					</template>
				</v-col>
			</v-row>
		</v-card-text>
		
		<v-card-text class="pa-0 ma-0 pr-1">
			<v-data-table
				:dense="$vuetify.breakpoint.lgAndDown"
				fixed-header
				:headers="headers"
				:height="tableHeight"
				:items="family"
				:loading="dataTableLoading"
				loading-text="Loading... Please wait"
				no-data-text="No records found."
				:options.sync="tableOptions"
				:page="tableOptions.page"
				:server-items-length="tableTotal"
				ref="dataTableFamily"
				@update:items-per-page="tableResize; getFamily();"
				@update:page="getFamily"
				@update:sort-desc="getFamily"
			>
				/* Header */
				<template v-slot:header.NAME="{ header }">
					{{ header.text }}<br/>RELATIONSHIP
				</template>
				
				<template v-slot:item.NAME="{ item }" >
					<div style="min-height:20px;">{{ item.NAME }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.RELATIONSHIP }}</div>
				</template>
				
				<template v-slot:header.BDAY="{ header }">
					{{ header.text }}<br/>PLACE OF BIRTH
				</template>
				
				<template v-slot:item.BDAY="{ item }" >
					<div style="min-height:20px;">{{ item.BDAY }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.POB }}</div>
				</template>
				
				<template v-slot:header.ADDRESS="{ header }">
					{{ header.text }}<br/>OCCUPATION
				</template>
				
				<template v-slot:item.ADDRESS="{ item }" >
					<div style="min-height:20px;">{{ item.ADDRESS }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.OCCUPATION }}</div>
				</template>
				
				<template v-slot:header.MARRIAGE_DATE="{ header }">
					{{ header.text }}<br/>PLACE OF MARRIAGE
				</template>
	
				<template v-slot:header.CONTACT="{ header }">
					{{ header.text }}<br/>EMAIL
				</template>
		
				<template v-slot:header.BENEFACTOR="{ header }">
					{{ header.text }}<br/>
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipBeneficiary }">
							<v-icon small color="deep-purple accent-1" v-on="onTooltipBeneficiary">mdi-checkbox-blank-circle</v-icon>
						</template>
						<small>Beneficiary</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipDependent }">
							<v-icon small color="green darken-2" v-on="onTooltipDependent">mdi-checkbox-blank-circle</v-icon>
						</template>
						<small>Dependent</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipContactEmergency }">
							<v-icon small color="orange darken-3" v-on="onTooltipContactEmergency">mdi-checkbox-blank-circle</v-icon>
						</template>
						<small>Emergency Contact</small>
					</v-tooltip>
				</template>
				/* Header */
			
				/* Content */
				<template v-slot:item.MARRIAGE_DATE="{ item }" >
					<div style="min-height:20px;">{{ item.MARRIAGE_DATE }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.MARRIAGE_PLACE }}</div>
				</template>
				
				<template v-slot:item.CONTACT="{ item }" >
					<div style="min-height:20px;">{{ item.CONTACT }}</div>
					<div v-if="$vuetify.breakpoint.smAndUp" style="min-height:20px;">{{ item.EMAIL }}</div>
				</template>
				
				<template v-slot:item.BENEFACTOR="{ item }" >
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipBeneficiary }">
							<v-icon small color="deep-purple accent-1" v-on="item.BENEFACTOR == 1? onTooltipBeneficiary : ''">{{ item.BENEFACTOR == 1? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
						</template>
						<small>Beneficiary</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipDependent }">
							<v-icon small color="green darken-2" v-on="item.DEPENDENT == 1? onTooltipDependent : ''">{{ item.DEPENDENT == 1? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
						</template>
						<small>Dependent</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onTooltipContactEmergency }">
							<v-icon small color="orange darken-3" v-on="item.CONTACT_PERSON == 1? onTooltipContactEmergency : ''">{{ item.CONTACT_PERSON == 1? 'mdi-checkbox-blank-circle' : 'mdi-checkbox-blank-circle-outline' }}</v-icon>
						</template>
						<small>Emergency Contact</small>
					</v-tooltip>
				</template>
		
				<template v-slot:item.action="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="success" @click="editItem(item)">mdi-pencil</v-icon>
						</template>
						<small>Edit</small>
					</v-tooltip>
					
					<v-tooltip bottom>
						<template v-slot:activator="{ on: onToolTip }">
							<v-icon small v-on="onToolTip" color="error" @click="dialogDelete = true;deleteIndex = item">mdi-delete</v-icon>
						</template>
						<small>Delete</small>
					</v-tooltip>
				</template>
				/* Content */
		
			</v-data-table>
			
			<v-dialog
				v-model="dialog"
				:fullscreen="$vuetify.breakpoint.xsOnly && !dialogLoading"
				max-width="650"
				:persistent="dialogLoading"
				scrollable
				@click:outside="resetFormValidation();"
				@keydown.enter="saveItem"
			>
				<v-card v-if="!dialogLoading">
				
					<v-card-title class="headline primary white--text" primary-title>
						{{ dialogTitle }}
						
						<v-spacer></v-spacer>
							
						<v-btn icon
							@click="$refs.form.resetValidation(); dialog = false;"
							class="white--text"
						>
							<v-icon small dark>mdi-close</v-icon>
						</v-btn>
					</v-card-title>
					
					<v-card-text>
						<v-form
							ref="form"
							v-model="validForm"
							lazy-validation
						>
							<v-row dense no-gutters>
								<v-col cols="12">
									<v-alert
										dense
										outlined
										border="left"
										:value="errorOn && dialog"
										:type="errorType"
										class="mt-2 py-1"
									>
										{{ errorMessage }}
									</v-alert>
								</v-col>
								
								<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pr-2">
									<v-text-field
										label="Name*"
										v-model="editedItem.NAME"
										hint="Complete name (e.g. Juan D. Cruz Jr.)"
										:rules="rulesName"
										validate-on-blur
										@blur="editedItem.NAME = trimName(editedItem.NAME)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="4" md="4" lg="4" xl="4">
									<v-select
										label="Relationship*"
										v-model="editedItem.RELATIONSHIP"
										v-if="!newRelation"
										:items="relationshipOption"
										item-text="val"
										item-value="val"
										:rules="rulesRelation"
										validate-on-blur
										@change="relationChanged"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newRelation = !newRelation; oldRelation = editedItem.RELATIONSHIP; editedItem.RELATIONSHIP = ''; relationChanged();">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-select>
									
									<v-text-field
										label="Relationship*"
										v-model="editedItem.RELATIONSHIP"
										v-if="newRelation"
										hint="Please specify here"
										persistent-hint
										:rules="rulesRelation"
										validate-on-blur
										@blur="editedItem.RELATIONSHIP = trimName(editedItem.RELATIONSHIP)"
										@change="relationChanged"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newRelation = !newRelation; editedItem.RELATIONSHIP = oldRelation; relationChanged();">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
							
								<v-col cols="12" sm="4" md="4" lg="4" xl="4">
									<v-text-field
										ref="inputMarriageDate"
										:label="labelMarriageDate"
										:type="marriageDateType"
										v-model="editedItem.MARRIAGE_DATE"
										:disabled="!marriageActive"
										:rules="rulesMarriageDate"
										validate-on-blur
										@focus="marriageDateType = 'date'"
										@blur="marriageDateType = (isValidDate(editedItem.MARRIAGE_DATE))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pl-2">
									<v-autocomplete
										ref="inputMarriagePlace"
										:label="labelMarriagePlace"
										v-model="editedItem.MARRIAGE_PLACE"
										:disabled="!marriageActive"
										v-if="!newMarriagePlace"
										hint="City, Province"
										:persistent-hint="marriageActive"
										clearable
										:items="optionMarriagePlace"
										item-text="cityAndProvince"
										item-value="cityAndProvince"
										:search-input.sync="searchMarriagePlace"
										:loading="searchingMarriagePlace"
										:no-data-text= "marriagePlaceNoDataText"
										:allow-overflow=false
										:rules="rulesMarriagePlace"
										validate-on-blur
										@focus="marriagePlaceOn = true"
										@blur="marriagePlaceOn = false"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newMarriagePlace = !newMarriagePlace; oldMarriagePlace = editedItem.MARRIAGE_PLACE; editedItem.MARRIAGE_PLACE = ''">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-autocomplete>
									
									<v-text-field
										ref="inputMarriagePlace"
										:label="labelMarriagePlace"
										v-model="editedItem.MARRIAGE_PLACE"
										:disabled="!marriageActive"
										v-if="newMarriagePlace"
										hint="Please specify here (eg. City, Province)"
										:persistent-hint="marriageActive"
										:rules="rulesMarriagePlace"
										validate-on-blur
										@blur="editedItem.MARRIAGE_PLACE = trimName(editedItem.MARRIAGE_PLACE)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newMarriagePlace = !newMarriagePlace; editedItem.MARRIAGE_PLACE = oldMarriagePlace;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
							
								<v-col cols="12" sm="4" md="4" lg="4" xl="4">
									<v-text-field
										label="Date of Birth*"
										hint="MM/DD/YYYY"
										persistent-hint
										:type="DOBType"
										v-model="editedItem.BDAY"
										:rules="rulesDOB"
										validate-on-blur
										@focus="DOBType = 'date'"
										@blur="DOBType = (isValidDate(DOBType))? 'date' : 'text'"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12" sm="8" md="8" lg="8" xl="8" class="pl-2">
									<v-autocomplete
										label="Place of Birth*"
										v-model="editedItem.POB"
										v-if="!newPOB"
										hint="City, Province"
										clearable
										:rules="rulesPOB"
										validate-on-blur
										:items="optionPOB"
										item-text="cityAndProvince"
										item-value="cityAndProvince"
										:search-input.sync="searchPOB"
										:loading="searchingPOB"
										:no-data-text= "POBNoDataText"
										:allow-overflow=false
										@focus="POBOn = true"
										@blur="POBOn = false"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newPOB = !newPOB; oldPOB = editedItem.POB; editedItem.POB = ''">mdi-plus</v-icon>
												</template>
												<small>Others</small>
											</v-tooltip>
										</template>
									</v-autocomplete>
									
									<v-text-field
										label="Place of Birth*"
										v-model="editedItem.POB"
										:rules="rulesPOB"
										validate-on-blur
										v-if="newPOB"
										hint="Please specify here (eg. City, Province)"
										@blur="editedItem.POB = trimName(editedItem.POB)"
										class="uppercase-input"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onToolTip }">
													<v-icon left v-on="onToolTip" @click="newPOB = !newPOB; editedItem.POB = oldPOB;">mdi-format-list-bulleted</v-icon>
												</template>
												<small>Select</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
							
								<v-col cols="12">
									<v-text-field
										label="Address"
										v-model="editedItem.ADDRESS"
										:rules="rulesAddress"
										validate-on-blur
										hint="House No. / Street Name / Brgy. Name, City, Province"
										@blur="editedItem.ADDRESS = trimName(editedItem.ADDRESS)"
										class="pr-2 uppercase-input"
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										ref="inputMobileNumber"
										label="Mobile Number"
										v-model="editedItem.CONTACT"
										v-if="!contactTelephone"
										v-mask="maskMobile"
										:rules="rulesContact"
										validate-on-blur
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onTooltipMobile }">
													<v-icon class="pa-0 ma-0" left v-on="onTooltipMobile" @click="$refs.inputMobileNumber.resetValidation(); contactTelephone = !contactTelephone; editedItem.CONTACT = '';">mdi-cellphone-android</v-icon>
												</template>
												<small>Switch</small>
											</v-tooltip>
										</template>
									</v-text-field>
									
									<v-text-field
										ref="inputTelephoneNumber"
										label="Telephone Number"
										v-model="editedItem.CONTACT"
										v-if="contactTelephone"
										v-mask="maskTelephone"
										maxlength="12"
										:rules="rulesContact"
										placeholder="00-0000-0000"
										hint="Area code (2) + 8 Digits landline number"
										validate-on-blur
										@keyup="telephoneFormat()"
										@blur="telephoneFormat()"
									>
										<template v-slot:append-outer>
											<v-tooltip bottom>
												<template v-slot:activator="{ on: onTooltipTelephone }">
													<v-icon class="pa-0 ma-0" left v-on="onTooltipTelephone" @click="$refs.inputTelephoneNumber.resetValidation(); contactTelephone = !contactTelephone; editedItem.CONTACT = '';">mdi-phone-classic</v-icon>
												</template>
												<small>Switch</small>
											</v-tooltip>
										</template>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Email"
										v-model="editedItem.EMAIL"
										:rules="rulesEmail"
										validate-on-blur
									>
									</v-text-field>
								</v-col>
								
								<v-col cols="12">
									<v-text-field
										label="Occupation"
										v-model="editedItem.OCCUPATION"
										:rules="rulesOccupation"
										validate-on-blur
										@blur="editedItem.OCCUPATION = trimName(editedItem.OCCUPATION)"
										class="uppercase-input"
									>
									</v-text-field>
								</v-col>
							</v-row>	
							
							<v-row dense no-gutters justify="space-between" class="mb-3">
								<v-col cols="12" class="caption">Declared as:</v-col>
								<v-checkbox
									label="Beneficiary"
									v-model="editedItem.BENEFACTOR"
									false-value="0"
									true-value="1"
									hide-details
									color="deep-purple accent-1"
									class="shrink mr-2 mt-0"
								></v-checkbox>
								
								<v-checkbox
									label="Dependent"
									v-model="editedItem.DEPENDENT"
									false-value="0"
									true-value="1"
									hide-details
									color="green darken-2"
									class="shrink mr-2 mt-0"
								></v-checkbox>
								
								<v-checkbox
									label="Emergency Contact"
									v-model="editedItem.CONTACT_PERSON"
									hide-details
									false-value="0"
									true-value="1"
									color="orange darken-3"
									class="shrink mr-2 mt-0"
								></v-checkbox>
							</v-row>
						</v-form>
					</v-card-text>
					
					<v-card-actions>
						<small class="pl-4 font-weight-light">Note: Field with asterisk(*) is required.</small>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="$refs.form.resetValidation(); dialog = false;">Cancel</v-btn>
						<v-btn color="blue darken-1" text @click="saveItem">Save</v-btn>
					</v-card-actions>
				</v-card>
				
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Saving...
						<v-progress-linear
							indeterminate
							color="white"
							class="mb-0"
						></v-progress-linear>
					</v-card-text>
				</v-card>
			</v-dialog>
			
			<v-dialog
				v-model="dialogDelete"
				max-width="355"
				:persistent="dialogLoading"
			>
				<v-card
					color="primary"
					dark
					v-if="dialogLoading"
				>
					<v-card-text class="pt-2">
						Deleting...
						<v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
					</v-card-text>
				</v-card>
				
				<v-card v-else-if="!dialogLoading">
					<v-card-title class="subtitle-1 warning white--text">CONFIRM DELETE</v-card-title>
					
					<v-card-text class="subtitle-1 font-weight-medium pt-5">Are you sure you want to delete this item?</v-card-text>
					
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn color="blue darken-1" text @click="dialogDelete = false">NO</v-btn>
						<v-btn color="blue darken-1" text @click="deleteItem()">YES</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
		
		</v-card-text>
	</v-card>
	
</template>

<script>
import { CLEAR_ALERT } from '@/store/actions/user';

import {mask} from 'vue-the-mask';
import { mapGetters } from 'vuex';

var timeoutData			= null;
var timeoutLoading		= null;
var timeoutTableResize	= null;
var timeoutDropdown		= null;
var timeoutMarriagePlace= null;
var timeoutPOB			= null;
var timeoutWindowResize	= null;
var timeoutDialogClose	= null;

export default {
	directives: {mask},
	data () {
		return {
			tableTotal			: 0,
			tableOptions		: {	page: 1, itemsPerPage: 10, sortBy: ['BDAY'], sortDesc: [false] },
			
			hoverNew				: false,
			hoverRefresh			: false,
			
			oldRelation				: '',
			oldMarriageDate			: '',
			oldMarriagePlace		: '',
			oldPOB					: '',
			
			newRelation				: false,
			
			marriageDateType		: 'text',
			marriageActive			: false,
			labelMarriageDate		: 'Date of Marriage',
			labelMarriagePlace		: 'Place of Marriage',
			
			newMarriagePlace		: false,
			optionMarriagePlace		: [],
			searchMarriagePlace		: null,
			searchingMarriagePlace	: false,
			marriagePlaceNoDataText	: 'Start typing to search',
			marriagePlaceOn			: false,
			
			newPOB					: false,
			optionPOB				: [],
			searchPOB				: null,
			searchingPOB			: false,
			POBNoDataText			: 'Start typing to search',
			POBOn					: false,
			DOBType					: 'text',
			
			contactTelephone		: false,
			
			isLoaded				: false,
			
			dialog					: false,
			validForm				: true,
			dialogDelete			: false,
			dialogLoading			: false,
			dataTableLoading		: true,
			isLoadedOptions			: false,
			
			tableHeight				: 'undefined',
			
			dateNow					: '',
			dateMin					: '',
			
			family					: [],
			relationshipOption		: [],
			
			maskDate				: '####-##-##',
			maskMobile				: '####-####-###',
			maskTelephone			: '##-####-####',
			
			deleteIndex				: [],
			editedIndex				: -1,
			editedItem: {
				ID					: '',
				NAME				: '',
				RELATIONSHIP		: '',
				BDAY				: '',
				POB					: '',
				ADDRESS				: '',
				CONTACT				: '',
				EMAIL				: '',
				OCCUPATION			: '',
				MARRIAGE_DATE		: '',
				MARRIAGE_PLACE		: '',
				BENEFACTOR			: 0,
				DEPENDENT			: 0,
				CONTACT_PERSON		: 0,
			},
			defaultItem: {
				ID					: '',
				NAME				: '',
				RELATIONSHIP		: '',
				BDAY				: '',
				POB					: '',
				ADDRESS				: '',
				CONTACT				: '',
				EMAIL				: '',
				OCCUPATION			: '',
				MARRIAGE_DATE		: '',
				MARRIAGE_PLACE		: '',
				BENEFACTOR			: 0,
				DEPENDENT			: 0,
				CONTACT_PERSON		: 0,
			},
			headers: [
				{ text: 'NAME',				value: 'NAME',					align: 'start',		sortable: false },
				{ text: 'RELATIONSHIP', 	value: 'RELATIONSHIP',			align: ' d-none',	sortable: false },
				{ text: 'DATE OF BIRTH', 	value: 'BDAY',					align: 'center' },
				{ text: 'PLACE OF BIRTH', 	value: 'POB',					align: ' d-none',	sortable: false },
				{ text: 'ADDRESS', 			value: 'ADDRESS',				align: 'center',	sortable: false },
				{ text: 'OCCUPATION', 		value: 'OCCUPATION',			align: ' d-none',	sortable: false },
				{ text: 'CONTACT NUMBER', 	value: 'CONTACT',				align: 'center',	sortable: false },
				{ text: 'EMAIL',		 	value: 'EMAIL',					align: ' d-none',	sortable: false },
				{ text: 'DATE OF MARRIAGE', value: 'MARRIAGE_DATE',			align: 'center',	sortable: false },
				{ text: 'PLACE OF MARRIAGE',value: 'MARRIAGE_PLACE',		align: ' d-none',	sortable: false },
				{ text: 'DECLARED AS', 		value: 'BENEFACTOR',			align: 'center',	sortable: false },
				{ text: 'ACTIONS',			value: 'action',				align: 'center',	sortable: false },
			],
			rulesName: [
				v => !!v || 'Name is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ.-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesRelation: [
				v => !!v || 'Relation is required',
				v => (!!v && this.stringLength(v) >= 3) || 'Must be atleast 3 characters',
				v => (v && this.stringLength(v) <= 30) || 'Must be less than 30 characters',
			],
			rulesMarriageDate: [
				v => ((!!v && this.marriageActive) || !this.marriageActive) || 'Marriage date is required',
			],
			rulesMarriagePlace: [
				v => ((!!v && this.marriageActive) || !this.marriageActive) || 'Marriage place is required',
				v => ((!!v && this.stringLength(v) >= 5) || (this.stringLength(v) == 0)) || 'Must be atleast 5 characters',
				v => ((!!v && this.stringLength(v) <= 100) || (this.stringLength(v) == 0)) || 'Must be less than 100 characters',
				v => !/[^a-zA-Z0-9ñÑ.,-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesDOB: [
				v => !!v || 'Relation is required',
				v => this.yearRange(v) || this.yearRangeError,
			],
			rulesPOB: [
				v => !!v || 'Place of Birth is required',
				v => (!!v && this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (!!v && this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-ZñÑ.,-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesAddress: [
				v => (Boolean(!v) || this.stringLength(v) >= 5) || 'Must be atleast 5 characters',
				v => (Boolean(!v) || this.stringLength(v) <= 100) || 'Must be less than 100 characters',
				v => !/[^a-zA-Z0-9ñÑ.,/#-\s]+/.test(v) || 'Must be alpha characters only',
			],
			rulesContact: [
				v => this.validContact(v) || this.contactError,
			],
			rulesEmail: [
				v => (Boolean(!v) || /.+@.+\..+/.test(v)) || 'Invalid email',
			],
			rulesOccupation: [
				v => (Boolean(!v) || this.stringLength(v) >= 2) || 'Must be atleast 2 characters',
				v => (Boolean(!v) || this.stringLength(v) <= 50) || 'Must be less than 50 characters',
			],
		}
	},
	methods: {
		getFamily () {
			if(this.dataTableLoading && typeof source !== 'undefined')source.cancel();
			
			const CancelToken = this.$http.CancelToken;
			let source = CancelToken.source();

			this.$store.dispatch(CLEAR_ALERT);
			this.dataTableLoading = true;
			this.isLoaded = false;
			
			clearTimeout(timeoutData);
				
			this.$http.get('family', { params:{ options: this.tableOptions } }, { cancelToken: source.token })
			.then((resp) => {
				this.family = resp.data.data;
				this.tableTotal = resp.data.rows;
				this.isLoaded = true;
				this.dropdown();
			})
			.catch(() => {
				timeoutData = setTimeout(() => {
					this.getFamily();
				}, 60000);
			})
			.finally(() => {
				this.dataTableLoading = false;
				this.tableResize();
			});
		},
		newItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
			
			setTimeout(() => {
				if(typeof this.$refs.form !== 'undefined')this.$refs.form.resetValidation();
			}, 50);
		},
		editItem: function(item){
			if(this.isValidDate(item.BDAY)){
				this.DOBType = 'date';
			}else{
				item.BDAY = '';
				this.DOBType = 'text';
			}
			
			if(this.isValidDate(item.MARRIAGE_DATE)){
				this.marriageDateType = 'date';
			}else{
				item.MARRIAGE_DATE = '';
				this.marriageDateType = 'text';
			}
			
			this.oldMarriageDate = item.MARRIAGE_DATE;
			this.oldMarriagePlace = item.MARRIAGE_PLACE;
			
			setTimeout(() => {
				if(item.MARRIAGE_PLACE != '' && this.marriageActive)this.optionMarriagePlace.push( {'cityAndProvince': item.MARRIAGE_PLACE} )
			}, 10);
		
			if(item.RELATIONSHIP != '')this.relationshipOption.push( {'val': item.RELATIONSHIP} );
			if(item.POB != '')this.optionPOB.push( {'cityAndProvince': item.POB} );
			
			if(item.CONTACT != null){
				const contactTemp = item.CONTACT.replace(/[^0-9]+/g, '').trim();
				this.contactTelephone  = Boolean( contactTemp.length <= 10 && contactTemp.length != 0);
			}
			if(this.contactTelephone)this.telephoneFormat();
			
			this.editedIndex = this.family.indexOf(item);
			this.editedItem = Object.assign({}, item);
			
			this.$store.dispatch(CLEAR_ALERT);
			this.dialog = true;
		},
		deleteItem: function(){
			this.$store.dispatch(CLEAR_ALERT);
			this.dialogLoading = true;
			this.$http.delete('family', {
				params: {
					ID: this.deleteIndex.ID
				}
			}).then(() => {
				const index = this.family.indexOf(this.deleteIndex);
				this.family.splice(index, 1);
				this.tableTotal--;
				this.tableResize();
			})
			.finally(() => {
				this.dialogDelete = false;
				clearTimeout(timeoutLoading);
				timeoutLoading = setTimeout(() => {
					this.dialogLoading = false;
				}, 300);
			});
		},
		saveItem: function(){
			if(this.$refs.form.validate()){
				this.$store.dispatch(CLEAR_ALERT);
				this.dialogLoading = true;
				
				if(this.editedIndex > -1){	//Edit Item
					this.$http.put('family', {
						forms			: this.editedItem,
						newRelation		: this.newRelation,
						newMarriagePlace: this.newMarriagePlace,
						newPOB			: this.newPOB
					}).then(() => {
						Object.assign(this.family[this.editedIndex], this.editedItem);
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}else{	//New Item
					this.$http.post('family', {
						forms			: this.editedItem,
						newRelation		: this.newRelation,
						newMarriagePlace: this.newMarriagePlace,
						newPOB			: this.newPOB
					}).then((response) => {
						this.editedItem.ID = response.data.ID;
						
						this.tableTotal++;
						this.family.unshift(this.editedItem);
						
						this.tableResize();
						this.dialog = false;
					})
					.catch(() => {
						this.dialog = true;
					})
					.finally(() => {
						clearTimeout(timeoutLoading);
						timeoutLoading = setTimeout(() => {
							this.dialogLoading = false;
						}, 300)
					});
				}
			}
		},
		tableResize(){
			clearTimeout(timeoutTableResize);
			timeoutTableResize = setTimeout(() => {
				if(typeof this.$refs.dataTableFamily !== 'undefined'){
					const windowWidth = this.$refs.dataTableFamily.$parent.$el.clientWidth;
					const tableWidth = this.$refs.dataTableFamily.$el.children[0].children[0].clientWidth;
					const scrollX = ( tableWidth > windowWidth)? 20 : 0;
				
					const tbodyHeight= this.$refs.dataTableFamily.$el.children[0].children[0].clientHeight;
					this.tableHeight = ( (this.userFormHeight - (40 + 65) ) > tbodyHeight )? tbodyHeight : this.userFormHeight - (40 + 65);
					this.tableHeight += scrollX;
				}
			}, 50);
		},
		dropdown(){
			if(!this.isLoadedOptions){
				clearTimeout(timeoutDropdown);
				this.$http.get('dropdown/familyRelation').then((resp) => {
					this.relationshipOption = resp.data;
				})
				.then(() => {
					this.isLoadedOptions = true;
				})
				.catch(() => {//retry
					timeoutDropdown = setTimeout(() => {
						this.dropdown();
					}, 10000)
				});
			}
		},
		resetFormValidation(){
			if(!this.dialogLoading)this.$refs.form.resetValidation();
		},
		compareDate(d1 , d2, opr){
			if( d1 == '' || d2 == '' || typeof d1 === 'undefined' || typeof d2 === 'undefined' ){
				return true;
			}else{
				var x = new Date(d1);
				var y = new Date(d2);
				return ( opr == '=' )? y >= x : y > x;
			}
		},
		relationChanged (){
			this.marriageActive = Boolean(this.editedItem.RELATIONSHIP == 'WIFE');
			
			if(this.marriageActive){
				this.editedItem.MARRIAGE_DATE = this.oldMarriageDate;
				this.editedItem.MARRIAGE_PLACE= this.oldMarriagePlace;
			}else{
				this.oldMarriageDate = this.editedItem.MARRIAGE_DATE;
				this.oldMarriagePlace = this.editedItem.MARRIAGE_PLACE;
				
				this.editedItem.MARRIAGE_DATE = this.editedItem.MARRIAGE_PLACE = '';
			}
			
			if(typeof this.$refs.inputMarriageDate !== 'undefined')this.$refs.inputMarriageDate.resetValidation();
			if(typeof this.$refs.inputMarriagePlace !== 'undefined')this.$refs.inputMarriagePlace.resetValidation();
			if(typeof this.$refs.inputMarriagePlaceNew !== 'undefined')this.$refs.inputMarriagePlaceNew.resetValidation();
		},
		validContact(val){
			var empty = Boolean(this.editedItem.CONTACT);
			if(empty){
				if( !this.contactTelephone ){ // mobile
					return /[0][9][0-9]{2}[-][0-9]{4}[-][0-9]{3}$/.test(val);
				}else{ // telephone
					const len = val.replace(/[^0-9]+/g, '').trim().length;
					return ( len == 7 || len == 8 || len == 10)? true : false;
				}
			}else{
				return true;
			}
		},
		telephoneFormat(){
			var tel = this.editedItem.CONTACT.replace(/[^0-9]+/g, '').trim();
			if(tel.length == 7){
				this.editedItem.CONTACT = tel.slice(0, 3)+'-'+tel.slice(3, 7);
			}else if(tel.length == 8){
				this.editedItem.CONTACT = tel.slice(0, 4)+'-'+tel.slice(4, 8);
			}else if(tel.length == 10){
				this.editedItem.CONTACT = tel.slice(0, 2)+'-'+tel.slice(2, 6)+'-'+tel.slice(6, 10);
			}
		},
		stringLength(text){
			return (typeof text === 'undefined' || text === null)? 0 : text.length;
		},
		yearRange (dob){
			return ( dob >= this.dateMin && dob <= this.dateNow )? true : false;
		},
		trimName(text){
			return (typeof text === 'undefined')? '' : text.toUpperCase().replace('ñ', 'Ñ').replace(/ +/g, ' ').trimStart().trim();
		},
		closeDialog () {
			this.dialog = false
			clearTimeout(timeoutDialogClose);
			timeoutDialogClose = setTimeout(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
				
				this.marriageActive = false;
				this.newRelation = this.newMarriagePlace = false;
				this.newPOB = this.contactTelephone = false;
				
				this.marriageDateType = this.DOBType = 'text';
			}, 50);
		},
		isValidDate(dateString) {
			if(dateString == null)return false;
			var regEx = /^\d{4}-\d{2}-\d{2}$/;
			if(!dateString.match(regEx)) return false;  // Invalid format
			var d = new Date(dateString);
			var dNum = d.getTime();
			if(!dNum && dNum !== 0) return false; // NaN value, Invalid date
			return d.toISOString().slice(0,10) === dateString;
		},
	},
	computed: {
		...mapGetters(['userFormHeight', 'serverDate', 'windowSize', 'errorOn', 'errorType', 'errorMessage']),
		dialogTitle () {
			return this.editedIndex === -1 ? 'New Family Member' : 'Edit Family Member'
		},
		yearRangeError (){
			return 'Out of range ('+this.dateMin+' - '+this.dateNow+')';
		},
		contactError (){
			return (this.contactTelephone)? 'Invalid telephone number' : 'Invalid mobile number';
		},
	},
	watch: {
		dialog (val) {
			val || this.closeDialog()
		},
		marriageActive(){
			this.labelMarriageDate = (this.marriageActive)? 'Date of Marriage*' :  'Date of Marriage';
			this.labelMarriagePlace= (this.marriageActive)? 'Place of Marriage*' :  'Place of Marriage';
			this.marriageDateType = (this.isValidDate(this.editedItem.MARRIAGE_DATE))? 'date' : 'text'
		},
		searchMarriagePlace(val){
			if(!this.marriagePlaceOn) return
			
			clearTimeout(timeoutMarriagePlace);
			
			timeoutMarriagePlace = setTimeout(() => {
				this.searchingMarriagePlace = true;
				this.marriagePlaceNoDataText = "Searching..."
				this.$http.get('autocomplete/citiesAndProvinces', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionMarriagePlace = res.data;
				}).finally(() => {
					this.searchingMarriagePlace = false;
					this.marriagePlaceNoDataText = (this.optionMarriagePlace.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		searchPOB(val){
			if(!this.POBOn) return
			
			clearTimeout(timeoutPOB);
			
			timeoutPOB = setTimeout(() => {
				this.searchingPOB = true;
				this.POBNoDataText = "Searching..."
				this.$http.get('autocomplete/citiesAndProvinces', {
					params: {
						search: val
					}
				})
				.then((res) => {
					this.optionPOB = res.data;
				}).finally(() => {
					this.searchingPOB = false;
					this.POBNoDataText = (this.optionPOB.length == 0)? "No data available" : "Searching...";
				});
			}, 500);
		},
		windowSize(newVal, oldVal){
			if( newVal != oldVal ){
				clearTimeout(timeoutWindowResize);
				timeoutWindowResize = setTimeout(() => {
					this.tableResize();
				}, 600)
			}
		},
	},
	created () {
		this.getFamily();
	},
	mounted (){
		this.dateNow = this.serverDate;
		this.dateMin = (this.serverDate.substr(0, 4) - 100)+this.serverDate.substr(4, 10);
	},
	beforeDestroy(){
		clearTimeout(timeoutData);
		clearTimeout(timeoutLoading);
		clearTimeout(timeoutDropdown);
		clearTimeout(timeoutWindowResize);
		clearTimeout(timeoutPOB);
		clearTimeout(timeoutTableResize);
		clearTimeout(timeoutMarriagePlace);
		clearTimeout(timeoutDialogClose);
	},
}
</script>

<style scoped>
	.uppercase-input >>> input {
		text-transform: uppercase
    }
</style>